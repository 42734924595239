import pdf from '../privacy_policy.pdf';


const PrivacyPolicy = () => {
    return <div>
        <iframe src={pdf} style={{ width: '100%', height: '100vh' }}></iframe>

    </div>;
};

export default PrivacyPolicy;