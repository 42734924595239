import MyNavbar from "./MyNavbar";
import React, {useState} from 'react';
import RegisterPagePhone from "./register_page_phone.png";
import ChatGPTFooter from "./ChatGPTFooter";
import NewsletterSignup from "./NewsletterSignup";


const AboutPage = () => {

    return (
        <div className=" bg-gradient-to-br from-yellowAccent to-greenAccent ">
            <div className="ml-3 mr-3 m-lg-0" style={{ minHeight: "calc(100vh - 96px)" }}>
                <div className=" text-center max-w-5xl mx-auto  pt-lg-4">
                    <MyNavbar/>

                    <section className="text-center max-w-5xl mx-auto pt-lg-4" id="about">
                        <div
                            className="rounded-3xl bg-lime-200 border-4 border-tealColor p-3 p-lg-5 m-lg-0 mb-lg-5 lg:m-0 mb-5">

                            <h1 className="text-teal-900 font-bold">Luckify-st</h1>
                            {/*<AboutAccordion/>*/}
                            <div className="text-start text-xl font-semibold mt-3 mt-lg-5">
                                <div>Luckify on leht, kus saab avastada erinevaid kampaaniaid, et võita
                                    igasuguseid auhindu.
                                </div>
                                <br/>
                                <div>Meie eesmärk on pakkuda mugavat ning kiiret võimalust leida
                                    erinevaid kampaaniaid, kust osa võtta ja võita auhindu.</div>
                                <br/>
                                <div>Meie lehel leiate mitmesuguseid kampaaniaid, alates ostukviitungi registreerimisest
                                    kuni küsitluste täitmiseni ning uudiskirjade tellimiseni. Luckify eesmärk on koguda
                                    kokku kõik käimasolevad kampaaniad ühte kohta, et oleks lihtsam leida
                                    erinevaid kampaaniaid ning nendest osa võtta.
                                </div>
                                <br/>
                                <div>
                                    Liitu meie uudiskirjaga juba täna, et saada iganädalasi teavitusi uutest kampaaniatest!
                                </div>

                                <NewsletterSignup/>



                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <ChatGPTFooter/>
        </div>
    );
}

export default AboutPage;