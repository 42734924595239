// firebaseConfig.js

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyA_29cr1__h0IYNT1iyERko0TGEcUdvf94",
    authDomain: "luckify-app.firebaseapp.com",
    projectId: "luckify-app",
    storageBucket: "luckify-app.appspot.com",
    messagingSenderId: "665241893106",
    appId: "1:665241893106:web:f7cb00b7e0f765176fb86b",
    measurementId: "G-R3L3F3NEL1"
};

const app = initializeApp(firebaseConfig);

export default app;
