import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

function NewsletterSignup() {
    const [email, setEmail] = useState("");

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSignup = async () => {
        if (email == null || email === "") {
            window.alert("Palun sisestage email.");
            return;
        }

        // Initialize Firestore
        const db = getFirestore();

        try {
            // Add the email to the Firestore collection "newsletter_subscribers"
            const docRef = await addDoc(collection(db, "newsletter_subscribers"), {
                email: email
            });

            window.alert("Uudiskirjaga liitunud!");

            // Optionally, you can reset the email input field after successful registration
            setEmail("");
        } catch (error) {
            console.error("Error adding document: ", error);
            window.alert("Midagi läks valesti. Palun proovige uuesti.");
        }
    }

    return (
        <div className="flex flex-col items-center mt-4 sm:flex-row sm:items-center sm:justify-center">
            <input
                type="email"
                className="border-2 border-tealColor p-2 rounded-xl w-full"
                placeholder="Sisestage email"
                value={email}
                onChange={handleEmailChange}
                autoComplete="email"
            />
            <button
                className="bg-tealColor text-white font-semibold px-4 py-2 rounded-xl mt-2 sm:ml-2"
                onClick={handleSignup}
            >
                Liitu!
            </button>
        </div>
    );
}

export default NewsletterSignup;
