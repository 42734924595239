import React, {useState} from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

const MyNavbar = (props) => {

    var english = props.english;
    return (
        <Navbar collapseOnSelect expand="lg" bg="custom" variant="light" className="font-bold">
            <Container style={{"padding-left": 0, "padding-right": 0}}>
                <Navbar.Brand href="/" className="flex">
                    <img
                        src="/luckylogorounded.svg"
                        width="64"
                        height="64"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                    <div className="hidden md:block align-self-center flex align-items-center ml-4 font-bold text-3xl">Luckify</div>

                </Navbar.Brand>
                <div className="flex align-items-center mr-6 font-bold text-3xl lg:hidden">Luckify</div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        {/*<Nav.Link href="#about">About</Nav.Link>*/}
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/about">Liitu uudiskirjaga</Nav.Link>
                        {/*<Nav.Link href="#support">*/}
                        {/*    Support*/}
                        {/*</Nav.Link>*/}
                        {/*<NavDropdown*/}
                        {/*    id="nav-dropdown-dark-example"*/}
                        {/*    title={english ? "English" : "Eesti"}*/}
                        {/*    menuVariant="dark"*/}
                        {/*>*/}
                        {/*    <NavDropdown.Item*/}
                        {/*        href={english ? "/" : "en"}>{english ? "Eesti" : "English"}</NavDropdown.Item>*/}
                        {/*</NavDropdown>*/}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MyNavbar;
