import React, {useEffect, useState} from 'react';
import {getFirestore, getDocs, collection, query, orderBy} from 'firebase/firestore';
import app from './firebaseConfig';
import MyNavbar from "./MyNavbar";
import ChatGPTFooter from "./ChatGPTFooter";
import {Button, Card} from "react-bootstrap";

function CampaignsList() {
    const [campaigns, setCampaigns] = useState([]);
    const firestore = getFirestore(app);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const campaignsRef = collection(firestore, 'campaigns');
                const campaignQuery = query(
                    campaignsRef, orderBy('expiryDate', 'desc') // Sort campaigns by expiryDate in ascending order
                );
                const campaignSnapshot = await getDocs(campaignQuery);

                const campaignData = campaignSnapshot.docs.map((doc) => ({
                    id: doc.id, // Document ID
                    ...doc.data(), // Campaign data
                }));

                setCampaigns(campaignData);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();
    }, []);

    // Calculate the number of days left until the campaign ends
    const calculateDaysLeft = (expiryDate) => {
        const now = new Date();
        const expiration = expiryDate.toDate();
        const timeDifference = expiration - now;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysLeft;
    };

    return (
        <div className="bg-gradient-to-br from-yellowAccent to-greenAccent ">
            <div className=" text-center max-w-5xl mx-auto  pt-lg-4">
                <MyNavbar/>
                <h2 className="font-bold tracking-widest">Kampaaniad</h2>
                <br/>
                <div>
                    {campaigns.map((campaign) => (
                        <div key={campaign.id} className="position-relative mb-10">
                            <Card className="!rounded-xl !border-none !bg-amber-50">
                                <a href={campaign.websiteUrl} target="_blank"><Card.Img
                                    className="!rounded-b-none rounded-t-xl" variant="bottom" src={campaign.url}/></a>
                                <Card.Body>
                                    <Card.Title className="text-left mb-3">{campaign.title}</Card.Title>
                                    <Card.Text className="text-left text-lg">{campaign.description}</Card.Text>
                                    <br/>
                                    <Button
                                        className="!rounded-xl !border-none !font-bold !bg-gradient-to-br from-yellowAccent to-greenAccent !text-black"
                                        href={campaign.websiteUrl} target="_blank">Loe lähemalt</Button>
                                </Card.Body>
                            </Card>
                            <div
                                className="text-lg font-bold rounded-md position-absolute top-0 end-0 m-2 text-yellow-300 bg-gray-700 px-2">
                                Päevi jäänud: {calculateDaysLeft(campaign.expiryDate)}
                            </div>
                            {/*<p>Expires on: {new Date(campaign.expiryDate.toDate()).toLocaleDateString()}</p>*/}
                        </div>
                    ))}
                </div>
            </div>
            <ChatGPTFooter/>
        </div>
    );
}

export default CampaignsList;
