import './App.css';
import MyNavbar from "./MyNavbar";
import {fadeInDown, fadeInRight} from "react-animations";
import {StyleSheet, css} from 'aphrodite';
import {Card, Image} from "react-bootstrap";
import LuckyPhone from './luckify_iphone.png'
import RegisterPagePhone from './register_page_phone.png'
import Lucky from './ph.png'
import MyFooter from "./MyFooter";
import ChatGPTFooter from "./ChatGPTFooter";
import AboutAccordion from "./AboutAccordion";
import 'bootstrap/dist/css/bootstrap.min.css';

import GPTNavbar from "./ChatGPTNavbar";

const styles = StyleSheet.create({
    fadeInDown: {
        animationName: fadeInDown,
        animationDuration: '1s'
    },
    fadeInRight: {
        animationName: fadeInRight,
        animationDuration: '1s'
    }
})


const Home = () => {
    var english = window.location.toString().includes("en");
    var text = english
        ? "Discover and easily register for new campaigns on Luckify™"
        : "Avasta uusi kampaaniaid ning võida erinevaid auhindu Luckify™ äpiga";
    var aboutText = english ? "About Luckify" : "Luckify-st"

    var comingSoonText = english ? "Coming soon for iOS and Android" : "Varsti saadaval iOS-il ning Android-il";
    var aboutDescText = english
        ? "Luckify is an app where you can find different campaigns by different products. " +
        "Thanks to Luckify finding new campaigns has been made easier and registering for campaigns a lot simpler."
        : "Luckify on äpp, kus leidub erinevate toodete kampaaniaid.\n" +
        "                            Tänu Luckify-le on kampaaniate leidmine tehtud lihtsamaks ning kampaaniatele\n" +
        "                            registreerimine mugavamaks. Saa kiiresti osa uutest kampaaniatest.";
    var howToText = english ? "How to register" : "Kuidas registreerida";
    var step1 = english ? "Choose a campaign where you would like to participate in." : "Vali endale kampaania kust sa tahaksid osa võtta.";
    var step2 = english ? "Buy that campaign's product and keep the receipt for now." : "Osta kampaaniale vastav toode ning hoia ostukviitung praeguseks alles.";
    var step3 = english ? "On the Luckify™ app, go to the campaign's page." : "Luckify™ äpis mine kampaania lehele.";
    var step4 = english ? "Tap on \"Register\" button and then upload the receipt(make sure the receipt's number and the campaign's product are visible on the receipt)." : "Vajuta \"Registreeri\" nuppu ning seejärel lae üles ostukviitungist pilt(vaata kindlasti, et kviitungi registrikood ning kampaania toode oleksid kviitungil näha).";
    var step5 = english ? "Confirm your registration and that's it! You are successfully registered to the campaign now. You no longer have to keep the receipt." : "Kinnita registreerimine ning seejärel oledki registreeritud kampaaniale! Ostukviitungit ei pea alles hoidma.";

    var question1 = english ? "Can you register to the same campaign multiple times?" : "Kas võib mitu korda registreerida samale kampaaniale?";
    var answer1 = english ? "That depends on the specific campaign itself, but usually you can register multiple times as long as you have a different receipt for each registration." : "See oleneb kampaaniast endast, aga tavaliselt kampaaniad lubavad " +
        "registreerida mitu korda erinevate ostudega. Ehk iga ostukviitung kampaania " + "tootega on 1 registreerimine.";
    var question2 = english ? "What is my user data used for?" : "Mida tehakse mu kasutaja andmetega?";
    var answer2 = english ? "User data is only used to register for campaigns." : "Kasutaja andmeid kasutatakse ainult kampaaniatele registreerimiseks.";
    var question3 = english ? "How will I know if I won a campaign?" : "Kuidas ma teada saan, kas ma võitsin?";
    var answer3 = english ? "The campaign organizers will contact you through email or phone." : "Kampaania korraldaja võtab teiega ühendust emaili või telefoni teel.";

    var productCampaignText = english ? "Why is Luckify good for your product campaign?" : "Miks on Luckify hea teie toote kampaaniale?";

    var visibilityText = english ? "Visibility" : "Nähtavus";
    var visibilityDescText = english ?
        "When adding a product campaign to the Luckify app, all Luckify users can see the product campaign and also receive a notification about the new campaign."
        : "Luckify äppi toote kampaania lisamisel näevad kõik Luckify kasutajad toote kampaaniat ning saavad ka teavituse uuest kampaaniast.";

    var convenienceText = english ? "Convenience" : "Mugavus";
    var convenienceDescText = english ?
        "Thanks to Luckify, registering for campaigns is much faster and more convenient. When registering for the app, the user has already saved all the required data (name, email, phone), which is why it is only necessary to take a picture of the purchase receipt when registering for the campaign. A faster and more convenient registration process increases participation in campaigns."
        : "Tänu Luckify-le on kampaaniatele registreerimine palju kiirem ning mugavam.\n" +
        "                                            Kasutajal on äpi registreerimisel kõik vajalikud andmed(nimi, email, telefon) juba salvestatud,\n" +
        "                                            mille tõttu kampaaniale registreerimisel on ainult vaja ostukviitungist pilt teha.\n" +
        "                                            Kiirem ja mugavam registreerimise protsess suurendab kampaaniate osavõttu.";


    var contactText = english ? "Contact" : "Kontakt";
    // var contactDescText = english ? "Please contact us here for any questions:" : "Lisa küsimuste korral võtke meiega ühendust:";
    var contactDescText = english ? "Contact us here:" : "Võta meiega ühendust: ";
    return (
        <div className=" bg-gradient-to-br from-yellowAccent  to-greenAccent">
            <div className="ml-3 mr-3 m-lg-0">

                <section>
                    <div className="lg:h-screen text-center max-w-5xl mx-auto  pt-lg-4">
                        <MyNavbar english={english}/>
                        {/*<GPTNavbar english={english}/>*/}
                        {/*from-pink-500 to-purple-600*/}
                        {/*<img src={Lucky} />*/}
                        <div className="">
                            <div className="flex flex-col lg:flex-row justify-content-between">
                                <h1 className={`mt-5 mb-5 text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-pink-500 font-extrabold text-center text-lg-start align-self-center max-w-lg text-left 
                            ${css(styles.fadeInDown)}`}>{text}</h1>
                                <div className="align-self-center">
                                    <img className={` ${css(styles.fadeInRight)}`}
                                         src={Lucky} width={315} height={600}/>
                                </div>
                            </div>
                            {/*<div*/}
                            {/*    className="flex justify-content-center justify-content-lg-start font-semibold text-gray-500">{comingSoonText}</div>*/}
                            <div
                                className="flex space-x-10 justify-content-center justify-content-lg-start pt-4 mb-5 lg:mb-0">
                                <a target="_blank" href="https://apps.apple.com/us/app/luckify/id6447539690">
                                    <img className="w-40 lg:w-48"
                                         width="200px"
                                         height="60px"
                                         src="https://logos-download.com/wp-content/uploads/2016/06/Download_on_the_App_Store_logo.png"/>
                                </a>

                                <a target="_blank" href="https://play.google.com/store/apps/details?id=io.luckify.app">
                                    <img className="w-40 lg:w-48 "
                                         width="200px"
                                         height="60px"
                                         src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/360px-Google_Play_Store_badge_EN.svg.png?20220907104002"
                                    />
                                </a>


                            </div>
                        </div>

                    </div>
                </section>


                <section className="text-center max-w-5xl mx-auto pt-lg-4" id="about">
                    <div
                        className="rounded-3xl bg-lime-200 border-4 border-tealColor p-3 p-lg-5 m-lg-0 mb-lg-5 lg:m-0 mb-5">

                        <h1 className="text-teal-900 font-bold">{aboutText}</h1>
                        {/*<AboutAccordion/>*/}
                        <div className="text-start text-xl font-semibold mt-3 mt-lg-5">
                            {aboutDescText}
                        </div>

                        <h3 className="text-start mt-5 text-blue-900">{howToText}</h3>
                        <div>
                            <div className="flex justify-content-between">
                                <div className="text-start">
                                    <div
                                        className="mt-3 mt-lg-5 max-w-sm rounded-2xl bg-yellow-100 border-2 border-tealColor flex">
                                        <p className="mt-3 ml-3 font-bold text-2xl align-self-center">
                                            1.</p>
                                        <p className="m-3 font-semibold text-lg">
                                            {step1}</p>
                                    </div>
                                    <div
                                        className="mt-3 mt-lg-5 max-w-sm rounded-2xl bg-yellow-100 border-2 border-solid border-tealColor flex">
                                        <p className="mt-3 ml-3 font-bold text-2xl align-self-center">
                                            2.</p>
                                        <p className="m-3 font-semibold text-lg">
                                            {step2}</p>
                                    </div>
                                    <div
                                        className="mt-3 mt-lg-5 max-w-sm rounded-2xl bg-yellow-100 border-2 border-tealColor flex">
                                        <p className="mt-3 ml-3 font-bold text-2xl align-self-center">
                                            3.</p>
                                        <p className="m-3 font-semibold text-lg">
                                            {step3}</p>
                                    </div>
                                    <div
                                        className="mt-3 mt-lg-5 max-w-sm rounded-2xl bg-yellow-100 border-2 border-tealColor flex">
                                        <p className="mt-3 ml-3 font-bold text-2xl align-self-center">
                                            4.</p>
                                        <p className="m-3 font-semibold text-lg">
                                            {step4}
                                        </p>
                                    </div>
                                    <div
                                        className="mt-3 mt-lg-5 max-w-sm rounded-2xl bg-yellow-100 border-2 border-tealColor flex">
                                        <p className="mt-3 ml-3 font-bold text-2xl align-self-center">
                                            5.</p>
                                        <p className="m-3 font-semibold text-lg">
                                            {step5}
                                        </p>
                                    </div>
                                </div>
                                <img className="h-max mt-5 hidden lg:block"
                                     src={RegisterPagePhone} width={315} height={700}/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-center max-w-5xl mx-auto  pt-lg-4" id="faq">
                    <div className="rounded-3xl bg-lime-200 border-4 border-tealColor p-3 p-lg-5 mb-5">

                        <h1 className="text-teal-900 font-bold">{productCampaignText}</h1>
                        {/*<AboutAccordion/>*/}

                        <div>
                            <div className="flex justify-content-between">
                                <div className="text-start">
                                    <h4 className="ml-2 text-start mt-3 mt-lg-5 text-blue-900">{visibilityText}</h4>
                                    <div
                                        className=" rounded-2xl bg-yellow-100 border-2 border-tealColor flex">

                                        <p className="m-3 font-semibold text-lg">
                                            {visibilityDescText}
                                        </p>
                                    </div>
                                    <h4 className="ml-2 text-start mt-3 mt-lg-5 text-blue-900">{convenienceText}</h4>

                                    <div
                                        className="rounded-2xl bg-yellow-100 border-2 border-solid border-tealColor flex">
                                        <p className="m-3 font-semibold text-lg">{convenienceDescText}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-center max-w-5xl mx-auto  pt-lg-4" id="faq">
                    <div className="rounded-3xl bg-lime-200 border-4 border-tealColor p-3 p-lg-5 mb-5">

                        <h1 className="text-teal-900 font-bold">FAQ</h1>
                        {/*<AboutAccordion/>*/}

                        <div>
                            <div className="flex justify-content-between">
                                <div className="text-start">
                                    <h4 className="ml-2 text-start mt-3 mt-lg-5 text-blue-900">{question1}</h4>

                                    <div
                                        className=" rounded-2xl bg-yellow-100 border-2 border-tealColor flex">

                                        <p className="m-3 font-semibold text-lg">
                                            {answer1}</p>
                                    </div>
                                    <h4 className="ml-2 text-start mt-3 mt-lg-5 text-blue-900">{question2}</h4>

                                    <div
                                        className="rounded-2xl bg-yellow-100 border-2 border-solid border-tealColor flex">
                                        <p className="m-3 font-semibold text-lg">{answer2}</p>
                                    </div>
                                    <h4 className="ml-2 text-start mt-3 mt-lg-5 text-blue-900">{question3}</h4>
                                    <div
                                        className=" rounded-2xl bg-yellow-100 border-2 border-tealColor flex">
                                        <p className="m-3 font-semibold text-lg">{answer3}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-start max-w-5xl mx-auto  pt-lg-4 mb-5" id="contact">
                    <div className="rounded-3xl bg-lime-200 border-4 border-tealColor p-3 p-lg-5 mb-5">

                        <h1 className="text-center text-teal-900 font-bold">{contactText}</h1>
                        {/*<AboutAccordion/>*/}

                        <h4 className="text-center mt-3 mt-lg-5 text-black">{contactDescText}
                            <a href="mailto:info@luckify.io" className="no-underline"> info@luckify.io</a></h4>


                    </div>
                </section>
            </div>
            <ChatGPTFooter/>
        </div>
    );
}
export default Home;
