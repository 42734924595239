import React, {useEffect, useState} from 'react';
import {getFirestore, getDocs, collection, query, orderBy} from 'firebase/firestore';
import app from './firebaseConfig';
import MyNavbar from './MyNavbar';
import ChatGPTFooter from './ChatGPTFooter';
import {Button, Card, ButtonGroup} from 'react-bootstrap';
import NewsletterSignup from "./NewsletterSignup";


// Import Firebase Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// Initialize Firebase Analytics
const analytics = getAnalytics();

// Track a campaign click event
function trackCampaignClick(campaignName) {
    logEvent(analytics, "campaign_click", {
        campaign: campaignName,
    });
}


function CampaignsList() {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null); // State for selected filter
    const firestore = getFirestore(app);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const campaignsRef = collection(firestore, 'web_campaigns');
                const orderByField = 'expiryDate';
                const orderDirection = 'desc';

                let campaignQuery = query(campaignsRef, orderBy(orderByField, orderDirection));

                const campaignSnapshot = await getDocs(campaignQuery);

                const campaignData = campaignSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setCampaigns(campaignData);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();
    }, []);

    // Calculate the number of days left until the campaign ends
    const calculateDaysLeft = (expiryDate) => {
        const now = new Date();
        const expiration = expiryDate.toDate();
        const timeDifference = expiration - now;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysLeft;
    };

    // Function to handle filter button clicks
    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
    };

    // Filter campaigns based on selected filter
    const filteredCampaigns = selectedFilter
        ? campaigns.filter((campaign) => campaign.type === selectedFilter)
        : campaigns;

    return (
        <div className="bg-gradient-to-br from-yellowAccent to-greenAccent ">
            <div className="ml-3 mr-3 m-lg-0">
                <div className=" text-center max-w-5xl mx-auto  pt-lg-4">
                    <MyNavbar/>
                    <br/>

                    <h2 className="font-bold tracking-widest">Kampaaniad</h2>
                    {/*<br/>*/}
                    {/*<div className="font-bold text-lg">Saa teavitusi uutest kampaaniatest!</div>*/}
                    {/*<NewsletterSignup/>*/}
                    <br/>

                    {/* Filter buttons */}
                    {/*<Button*/}
                    {/*    variant="custom"*/}
                    {/*    className="mx-2 !border-none !font-bold !bg-gradient-to-br from-purple-600 to-pink-500 !text-black !rounded-lg"*/}
                    {/*    onClick={() => handleFilterClick(null)}*/}
                    {/*    active={selectedFilter === null}*/}
                    {/*>*/}
                    {/*    Kõik*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    variant="custom"*/}
                    {/*    className="mx-2 !border-none !font-bold bg-white !text-black !rounded-lg"*/}
                    {/*    onClick={() => handleFilterClick(null)}*/}
                    {/*    active={selectedFilter === "kviitung"}*/}
                    {/*>*/}
                    {/*    Ostutšekiga*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    variant="custom"*/}
                    {/*    className="mx-2 !border-none !font-bold !bg-orange-500 !text-white !rounded-lg"*/}
                    {/*    onClick={() => handleFilterClick('partnerkaart')}*/}
                    {/*    active={selectedFilter === 'partnerkaart'}*/}
                    {/*>*/}
                    {/*    Partnerkaart*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    variant="custom"*/}
                    {/*    className="mx-2 !border-none !font-bold !bg-red-600 !text-white !rounded-lg"*/}
                    {/*    onClick={() => handleFilterClick('rimi')}*/}
                    {/*    active={selectedFilter === 'rimi'}*/}
                    {/*>*/}
                    {/*    Rimi*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    variant="custom"*/}
                    {/*    className="mx-2 !border-none !font-bold !bg-orange-400 !text-white !rounded-lg"*/}
                    {/*    onClick={() => handleFilterClick('aitäh')}*/}
                    {/*    active={selectedFilter === 'aitäh'}*/}
                    {/*>*/}
                    {/*    aitäh*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    variant="custom"*/}
                    {/*    className="mx-2 !border-none !font-bold !bg-blue-500 !text-white !rounded-lg"*/}
                    {/*    onClick={() => handleFilterClick('coop')}*/}
                    {/*    active={selectedFilter === 'coop'}*/}
                    {/*>*/}
                    {/*    Coop*/}
                    {/*</Button>*/}


                    <div>
                        {filteredCampaigns.map((campaign) => (
                            <div key={campaign.id} className="position-relative mb-10">
                                <Card className="!rounded-xl !border-none !bg-amber-50">
                                    <a href={campaign.websiteUrl} onClick={() => trackCampaignClick(campaign.title)} target="_blank">
                                        <Card.Img className="!rounded-b-none rounded-t-xl" variant="bottom"
                                                  src={campaign.url}/>
                                    </a>
                                    <Card.Body>
                                        <Card.Title className="text-left mb-3 !font-bold">{campaign.title}</Card.Title>
                                        <Card.Text className="text-left text-lg ">{campaign.description}</Card.Text>
                                        <br/>
                                        <Button
                                            className="!rounded-xl !border-none !font-bold !bg-gradient-to-br from-yellowAccent to-greenAccent !text-black"
                                            href={campaign.websiteUrl}
                                            onClick={() => trackCampaignClick(campaign.title)}
                                            target="_blank"
                                        >
                                            Loe lähemalt
                                        </Button>
                                    </Card.Body>
                                </Card>
                                <div
                                    className="text-lg font-bold rounded-md position-absolute top-0 end-0 m-2 text-yellow-300 bg-gray-700 px-2">
                                    Päevi jäänud: {calculateDaysLeft(campaign.expiryDate)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ChatGPTFooter/>
        </div>
    );
}

export default CampaignsList;
