import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import Home from "./Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Rules from "./pages/Rules";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Campaign7Days from "./pages/campaigns/Campaign7Days";
import Testing from "./pages/Testing";
import TaffelCampaign from "./pages/campaigns/TaffelCampaign";
import Campaigns from "./pages/Campaigns";

// Import the functions you need from the SDKs you need
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import {useEffect, useState} from "react";

import app from './firebaseConfig';
import { getDocs, collection, query, where, orderBy, limit } from 'firebase/firestore';
import CampaignsList from "./CampaignsList";
import OldCampaignsList from "./OldCampaignsList";
import AboutPage from "./AboutPage";




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA_29cr1__h0IYNT1iyERko0TGEcUdvf94",
    authDomain: "luckify-app.firebaseapp.com",
    projectId: "luckify-app",
    storageBucket: "luckify-app.appspot.com",
    messagingSenderId: "665241893106",
    appId: "1:665241893106:web:f7cb00b7e0f765176fb86b",
    measurementId: "G-R3L3F3NEL1"
};

// Initialize Firebase
const analytics = getAnalytics(app);

export default function App() {

    return (
        <BrowserRouter>
            <Routes>


                {/*<Route path="/" element={<Home/>}/>*/}

                <Route path="/" element={<CampaignsList/>}/>
                {/*<Route path="/testing" element={<Testing/>}/>*/}
                {/*<Route path="/7days" element={<Campaign7Days/>}/>*/}
                {/*<Route path="/taffel" element={<TaffelCampaign/>}/>*/}
                {/*<Route path="/en" element={<Home/>}/>*/}
                {/*<Route path="/en" element={<Home/>}/>*/}
                {/*<Route path="rules" element={<Rules/>}/>*/}
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/campaigns" element={<CampaignsList/>}/>
                <Route path="/about" element={<AboutPage/>}/>

            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);