import React from 'react';

function ChatGPTFooter() {
    return (
        <footer className="bg-dark text-white py-8">
            <div className="max-w-5xl mx-auto flex justify-between items-center">
                <div className="text-white font-bold text-2xl ml-3 m-lg-0">Luckify</div>
                {/*<div className="text-gray-400 text-sm">*/}
                {/*    <p className="mb-1">Contact us at:</p>*/}
                {/*    <a href="mailto:info@luckify.io" className="no-underline">info@luckify.io</a>*/}
                {/*</div>*/}
                {/*<div className="text-gray-400 text-sm mr-3 m-lg-0">&copy; {new Date().getFullYear()} Luckify Inc. All rights reserved</div>*/}
            </div>
        </footer>
    );
}

export default ChatGPTFooter;
